import React from "react";
import { PageProps, Link, graphql } from "gatsby";

import { Layout } from "../components/globals/Layout";
import { Image } from "../components/globals/Image";
import { SEO } from "../components/globals/SEO";
import { ArticleList } from "../components/ArticleList";
import { Site, KleinboyArticle, KleinboyTag } from "../types";
import { fromEntries } from "../utils/map";

export const query = graphql`
  query loadKleinboyArticleQuery($skip: Int = 0, $limit: Int = 1000) {
    site {
      siteMetadata {
        title
        authorName
        homepageTitle
        homepageURL
      }
    }
    allKleinboyArticle(sort: { order: DESC, fields: publishedTime }, skip: $skip, limit: $limit) {
      nodes {
        id
        path
        title
        description
        tags
        images
        publishedTime
        modifiedTime
      }
    }
    allKleinboyTag {
      nodes {
        key
        title
      }
    }
  }
`;

type DataProps = {
  site: Site;
  allKleinboyArticle: {
    nodes: KleinboyArticle[];
  };
  allKleinboyTag: { nodes: KleinboyTag[] };
};

const IndexPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const {
    allKleinboyArticle: { nodes },
  } = data;
  const tagMap = fromEntries(data.allKleinboyTag.nodes.map((n) => [n.key, n]));
  return (
    <Layout top>
      <SEO title="Home" />
      <div className="top">
        <h2>記事の一覧</h2>
        <ArticleList articles={nodes} tagMap={tagMap} />
      </div>
    </Layout>
  );
};

export default IndexPage;
